.cardGrid1 {
    position: relative;
    width: 100%;
    height: 30vh;
}
.card1 {
    margin-top: 70%;
    margin-left: 15%;
    background: #fff;
    box-shadow: inset 0 0 0 1px #d0d0d0;
    -webkit-box-shadow: inset 0 0 0 1px #d0d0d0;
    left: calc(50% - 20rem / 2);
    height: 12.5rem;
    overflow: hidden;
    /* position: absolute; */
    top: calc(50% - 12.5rem / 2);
    width: 20rem;
    border-left: 13px solid #0e6cad;
    border-radius: 10px;
    cursor: pointer;
}

.badCard1 {
    background: #fff;
    box-shadow: inset 0 0 0 1px #d0d0d0;
    -webkit-box-shadow: inset 0 0 0 1px #d0d0d0;
    left: calc(50% - 20rem / 2);
    height: 12.5rem;
    overflow: hidden;
    top: calc(50% - 12.5rem / 2);
    width: 20rem;
    border-left: 13px solid #cc1414;
    border-radius: 10px;
    cursor: pointer;
}

[class*='card-edge'] {
    background: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 50%;
    height: 3rem;
    position: absolute;
    width: 3rem;
}
[class*='top'] {
    top: -29px;
}
[class*='bottom'] {
    top: calc(12.5rem - 1rem);
}
[class*='left'] {
    left: -18px;
}
[class*='right'] {
    right: -18px;
}
.table1 {
    width: 80%;
    padding: 5px;
    margin-top: 15px;
    margin-left: 20px;
    /* display: flex; */
}
.card-name1 {
    font-size: 16px;
    font-weight: 500;
}
